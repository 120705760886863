import { breakpoints } from '$theme';
import styled from '@emotion/styled';
import { ifProp, switchProp } from 'styled-tools';
import { cover, linearBackground } from '~/shared/utils/styled';
import { Category } from '~/templates/blocks/components/Category';
import { getClampFullRange } from '~/theme/utils/getClampValue';
import { M11HeroProps } from './M11Hero';
import { MediaAlignmentComposition } from '~/lib/data-contract';
import { Link } from '~/shared/components';

type StyledM11Props = Pick<M11HeroProps, 'height' | 'backgroundColor'> & {
    gridTemplateColumns?: string;
    mediaAlignment: MediaAlignmentComposition['mediaHorizontalAlignment'];
    isTwoColumn: boolean;
};
type StyledM11ContentProps = {
    height: M11HeroProps['height'];
    contentAlignment: M11HeroProps['horizontalAlignment'];
    gradient?: boolean;
};

export const StyledM11 = styled.div<StyledM11Props>(
    ({ theme, backgroundColor, isTwoColumn, mediaAlignment }) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spaces[4],
        width: '100%',
        margin: '0 auto',
        backgroundColor,

        [breakpoints.md]: {
            gap: isTwoColumn ? theme.spaces[5] : 'initial',
            padding: theme.spaces[6],
            flexDirection: mediaAlignment === 'right' ? 'row-reverse' : 'row',
        },
    }),
    switchProp('height', {
        compact: {
            minHeight: getClampFullRange(150, 225),
            [breakpoints.sm]: {
                minHeight: `min(40vh, ${getClampFullRange(225, 300)})`,
            },
        },
        narrow: {
            minHeight: getClampFullRange(350, 525),
            [breakpoints.sm]: {
                minHeight: `min(50vh, ${getClampFullRange(350, 525)})`,
            },
        },
        normal: {
            minHeight: getClampFullRange(450, 675),
            [breakpoints.sm]: {
                minHeight: `min(70vh, ${getClampFullRange(450, 675)})`,
            },
        },
        tall: ({ theme: { sizes } }) => ({
            minHeight: getClampFullRange(600, 900),
            [breakpoints.sm]: {
                minHeight: `min(calc(100vh - ${
                    sizes.TotalHeaderHeightDesktop
                }px), ${getClampFullRange(600, 900)})`,
            },
        }),
    })
);

export const StyledM11MainContent = styled.div<StyledM11ContentProps>(
    ({ theme }) => ({
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '1fr auto',
        flex: 1,
        height: 'auto',
        minHeight: 'inherit',
        maxHeight: 1200,
        width: '100%',
        margin: '0 auto',
        [breakpoints.xs]: {
            maxWidth: '100%',
        },
        minWidth: `calc(66% - ${theme.spaces[5]})`,
    }),
    ifProp('gradient', ({ theme }) => ({
        '&:after': {
            content: "''",
            ...linearBackground(theme.colors.dark),
            pointerEvents: 'none',
        },
    })),
    switchProp('contentAlignment', {
        left: {
            justifyContent: 'flex-start',
        },
        center: {
            justifyContent: 'center',
        },
        right: {
            justifyContent: 'flex-end',
        },
    })
);

export const StyledM11MediaContainer = styled(Link)({
    position: 'relative',
});

export const StyledMedia = styled.div({
    ...cover,
});

export const StyledM11CategoryContent = styled.div({
    minWidth: '35%',
    [breakpoints.lg]: {
        minWidth: '30%',
    },
});

export const StyledM11CategoryList = styled.ul<{ isSingleItem: boolean }>(
    ({ theme, isSingleItem }) => ({
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: theme.spaces[4],
        [breakpoints.sm]: {
            gridTemplateColumns: isSingleItem ? '1fr' : '1fr 1fr',
        },
        [breakpoints.md]: {
            padding: 'initial',
            flexDirection: 'column',
            height: '100%',
            display: 'flex',
        },
    })
);

export const StyledCategory = styled(Category)<{ hasTextBlock: boolean }>(({ hasTextBlock }) => ({
    aspectRatio: `${hasTextBlock ? '1 / 1' : '4 / 3'}`,

    [breakpoints.sm]: {
        aspectRatio: 'unset',
    },
}));
