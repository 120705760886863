import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';
import { Variants } from './TextBlockCTA';
import { breakpoints } from '~/theme';

export const StyledTextBlockCTA = styled.div<{ variant?: Variants }>(
    ({ theme }) => ({
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        gap: theme.spaces[3],
        minHeight: theme.sizes.contentCardMinHeight,
        padding: `${theme.spaces[3]} ${theme.spaces[4]}`,
        paddingBottom: theme.spaces[5],
        [breakpoints.md]: {
            padding: `${theme.spaces[4]} ${theme.spaces[4]}`,
            minHeight: theme.sizes.contentCardMinHeight,
        },
    }),
    switchProp('variant', {
        expressive: ({ theme }) => ({
            alignItems: 'center',
            flexDirection: 'column',
            gap: theme.spaces[3],
            [breakpoints.md]: {
                flexDirection: 'row',
            },
        }),
    })
);

export const StyledTexts = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spaces[1],
}));

export const StyledCTATextWrapper = styled.div(({ theme }) => ({
    display: 'none',
    [breakpoints.md]: {
        fontWeight: theme.fontWeights.medium,
        display: 'block',
    },
}));
