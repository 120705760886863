import React from 'react';
import { MediaAndTextItem } from '~/lib/data-contract';
import { ImageAspectRatio } from '$templates/blocks/components/Category/styled';
import {
    StyledCategory,
    StyledM11CategoryContent,
    StyledM11CategoryList,
} from '$templates/blocks/components/M11Hero/styled';
import { weakKey } from '$shared/utils/jsx';

const getAspectRatio = (amountOfCategories: number): ImageAspectRatio => {
    if (amountOfCategories === 1) {
        return {
            mobile: [1, 1],
            desktop: [1, 1],
        };
    }
    if (amountOfCategories <= 2) {
        return {
            mobile: [10, 9],
            desktop: [1, 1],
        };
    }
    return {
        mobile: [4, 3],
        desktop: [3, 1],
    };
};

type M11ListItemsPropsType = {
    listItems?: MediaAndTextItem[];
    backgroundColor?: string;
    hasCategoriesMediaFilter?: boolean;
    height?: 'compact' | 'narrow' | 'normal' | 'tall';
};
export const M11HeroListItems = ({
    listItems,
    backgroundColor,
    hasCategoriesMediaFilter,
    height,
}: M11ListItemsPropsType) => {
    const amountOfCategories = listItems?.length || 0;
    const imagesVW = amountOfCategories === 1 && height === 'tall' ? 60 : 30;

    const isSingleItem = amountOfCategories === 1;

    return listItems?.length ? (
        <StyledM11CategoryContent>
            <StyledM11CategoryList isSingleItem={isSingleItem}>
                {listItems.map((item) => (
                    <StyledCategory
                        hasTextBlock={Boolean(item?.headline || item?.description)}
                        {...item}
                        imageAspectRatio={getAspectRatio(amountOfCategories)}
                        imagesVW={imagesVW}
                        key={weakKey(item)}
                        backgroundColor={backgroundColor}
                        textPosition="below"
                        stretch
                        hoverStyle={hasCategoriesMediaFilter ? 'both' : 'active'}
                    />
                ))}
            </StyledM11CategoryList>
        </StyledM11CategoryContent>
    ) : null;
};
