import React from 'react';
import { Link as LinkType } from '~/lib/data-contract';
import { Button, Link, Text, TextVariants } from '~/shared/components';
import { UseThemeShadeProps, useThemeShade } from '~/theme';
import { StyledCTATextWrapper, StyledTextBlockCTA, StyledTexts } from './styled';
import RightArrow from '$icons/arrow-right.svg';
import { useTheme } from '@emotion/react';
import { ConditionalWrap } from '~/shared/utils/jsx';

export type Variants = 'minimized' | 'expressive';

type Props = {
    headline?: string;
    headlineVariant?: TextVariants;
    text?: string;
    subheadline?: string;
    subHeadlineUppercase?: boolean;
    callToAction?: LinkType;
    variant?: Variants;
} & UseThemeShadeProps;

export const TextBlockCTA = ({
    backgroundColor,
    headline,
    subheadline,
    callToAction,
    headlineVariant,
    text,
    subHeadlineUppercase = true,
    variant,
}: Props) => {
    const theme = useTheme();
    const backgroundColorWithFallback = backgroundColor ?? theme.traits.background.default;
    const moduleTheme = useThemeShade({ backgroundColor: backgroundColorWithFallback });
    const CTATextWrapper = variant === 'expressive' ? React.Fragment : StyledCTATextWrapper;

    return (
        <ConditionalWrap
            if={Boolean(callToAction?.url)}
            wrap={(children) => (
                <Link href={callToAction?.url || ''} renderEmptyLinksAsDiv>
                    {children}
                </Link>
            )}
        >
            <StyledTextBlockCTA
                style={{ backgroundColor: moduleTheme.backgroundColor }}
                variant={variant}
            >
                <StyledTexts>
                    {subheadline && (
                        <Text
                            shade={moduleTheme.headlineShade}
                            variant={subHeadlineUppercase ? 'overline' : 'caption'}
                        >
                            {subheadline}
                        </Text>
                    )}
                    {headline && (
                        <Text variant={headlineVariant} shade={moduleTheme.headlineShade}>
                            {headline}
                        </Text>
                    )}
                    {text && (
                        <Text shade={moduleTheme.textShade} variant="bodySm">
                            {text}
                        </Text>
                    )}
                </StyledTexts>

                {callToAction?.url && (
                    <Button
                        as="span"
                        variant={variant === 'expressive' ? 'primary' : 'tertiary'}
                        shade={moduleTheme.buttonShade}
                        showHoverIndicator={false}
                    >
                        <CTATextWrapper>{callToAction.text || callToAction.title}</CTATextWrapper>
                        {variant !== 'expressive' && <RightArrow />}
                    </Button>
                )}
            </StyledTextBlockCTA>
        </ConditionalWrap>
    );
};
