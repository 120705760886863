import React, { memo, useEffect, useMemo, useState } from 'react';
import { M11HeroModule } from '~/lib/data-contract';
import { useCookieConsent } from '~/shared/hooks/useCookieConsent';
import { useModule } from '~/templates';
import { ModuleContainer } from '../ModuleContainer';
import { queriesIsolated, useContrastThemeShade } from '~/theme';
import {
    StyledM11,
    StyledM11MainContent,
    StyledMedia,
    StyledM11MediaContainer,
} from './styled';
import { itemMapper } from '~/templates/blocks/utils/itemMapper';

import { M11HeroImage } from './M11HeroImage';
import { M11HeroVideo } from './M11HeroVideo';
import { M11HeroListItems } from './M11HeroListItems';
import { TextBlockCTA } from '../TextBlockCTA';

export type M11HeroProps = M11HeroModule;

export const M11Hero = memo((props: M11HeroProps) => {
    const {
        headline,
        subHeadline,
        subHeadlineUppercase,
        text,
        height: heightTemp,
        horizontalAlignment = 'left',
        spacingTop,
        categories,
        backgroundColor: initialBackgroundColor,
        hasCategoriesMediaFilter,
        hideMobile,
        hideDesktop,
        ...rest
    } = props;
    const height = heightTemp || 'normal';
    const { index } = useModule();

    const moduleTheme = useContrastThemeShade({
        backgroundColor: initialBackgroundColor,
    });

    const { backgroundColor, skeletonShade } = moduleTheme || {};
    const { marketing, statistic } = useCookieConsent();

    const firstItem = categories?.[0];
    const listItems = useMemo(() => itemMapper(categories)?.slice(1) || [], [categories]);

    const [mediaType, setMediaType] = useState<'video' | 'image'>(
        firstItem?.video?.src && marketing && statistic ? 'video' : 'image'
    );

    // Force higher quality on mobile
    const sizes = `
            ${queriesIsolated.xs} 200vw, ${queriesIsolated.sm} 200vw, ${queriesIsolated.lg} ${
        listItems.length ? '60vw' : '80vw'
    }, 100vw`;

    const hasMediaGradient = firstItem?.hasMediaFilter && mediaType === 'image';
    const hasTextContent = Boolean(headline || subHeadline || text || firstItem?.callToAction);

    useEffect(() => {
        setMediaType(firstItem?.video?.src && marketing && statistic ? 'video' : 'image');
    }, [marketing, statistic, firstItem?.video, setMediaType]);

    const shouldRenderTextContent = Boolean(
        headline || subHeadline || text || firstItem?.callToAction
    );

    return (
        <ModuleContainer
            {...rest}
            spacingTop={index === 0 ? 'none' : spacingTop}
            hideMobile={hideMobile}
            hideDesktop={hideDesktop}
        >
            <StyledM11
                height={height}
                backgroundColor={backgroundColor}
                mediaAlignment={firstItem?.mediaHorizontalAlignment || 'left'}
                isTwoColumn={Boolean(listItems.length)}
            >
                {firstItem && (
                    <StyledM11MainContent
                        contentAlignment={horizontalAlignment}
                        height={height}
                        gradient={hasMediaGradient}
                    >
                        <StyledM11MediaContainer
                            renderEmptyLinksAsDiv
                            href={firstItem.callToAction?.url || ''}
                        >
                            <StyledMedia>
                                {mediaType === 'image' && (
                                    <M11HeroImage
                                        {...{
                                            mediaType,
                                            image: firstItem.image,
                                            index,
                                            skeletonShade,
                                            sizes,
                                            height,
                                        }}
                                    />
                                )}
                                {mediaType === 'video' && (
                                    <M11HeroVideo
                                        {...{
                                            video: firstItem.video,
                                            hasTextContent,
                                            imageSrc: firstItem.image?.src,
                                            posterSizes: sizes,
                                        }}
                                    />
                                )}
                            </StyledMedia>
                        </StyledM11MediaContainer>

                        {shouldRenderTextContent && (
                            <TextBlockCTA
                                headline={headline || firstItem.title}
                                headlineVariant={index === 0 ? 'display3' : 'display4'}
                                subheadline={subHeadline}
                                subHeadlineUppercase={subHeadlineUppercase}
                                text={text || firstItem.text}
                                callToAction={firstItem.callToAction}
                                backgroundColor={moduleTheme.backgroundContrastColor}
                            />
                        )}
                    </StyledM11MainContent>
                )}
                <M11HeroListItems
                    {...{
                        listItems,
                        backgroundColor: moduleTheme.backgroundContrastColor,
                        hasCategoriesMediaFilter,
                        height,
                    }}
                />
            </StyledM11>
        </ModuleContainer>
    );
});

export default M11Hero;
